import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const STRIPE_SECRET_KEY = `${process.env.REACT_APP_STRIPE_SECRET_KEY}`;

// Check if customer exists
export const fetchCustomer = createAsyncThunk(
  'stripe/fetchCustomer',
  async (email, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `https://api.stripe.com/v1/customers?email=${email}`,
        {
          method: 'GET',
          headers: { Authorization: `Bearer ${STRIPE_SECRET_KEY}` }
        }
      );

      const data = await response.json();
      return data.data.length > 0 ? data.data[0] : null;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Create customer if not exists
export const createCustomer = createAsyncThunk(
  'stripe/createCustomer',
  async ({ agency, token }, { rejectWithValue }) => {
    try {
      const bodyParams = new URLSearchParams({
        email: agency.email,
        source: token.id,
        name: agency.name || 'John Doe',
        description: 'Wallet customer'
      });

      Object.entries(agency).forEach(([key, value]) => {
        if (value) bodyParams.append(`metadata[${key}]`, value);
      });

      const response = await fetch('https://api.stripe.com/v1/customers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${STRIPE_SECRET_KEY}`
        },
        body: bodyParams
      });

      const data = await response.json();
      if (data.error) throw new Error(data.error.message);

      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Create product
export const createProduct = createAsyncThunk(
  'stripe/createProduct',
  async (CustomerData, { getState, rejectWithValue }) => {
    const searchResponse = await fetch(
      `https://api.stripe.com/v1/products/search?query=metadata['customer_id']:'${CustomerData.id}'`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${STRIPE_SECRET_KEY}`
        }
      }
    );

    const searchData = await searchResponse.json();
    if (searchData.error) throw new Error(searchData.error.message);

    if (searchData.data.length > 0) {
      return searchData.data[0]; // Return the first existing product
    }

    try {
      const productResponse = await fetch(
        'https://api.stripe.com/v1/products',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${STRIPE_SECRET_KEY}`
          },
          body: new URLSearchParams({
            name: `Custom Plan`,
            description: 'User-created subscription plan',
            'metadata[customer_id]': CustomerData.id // Store customer ID separately
          })
        }
      );

      const data = await productResponse.json();
      if (data.error) throw new Error(data.error.message);

      // Return the newly created product data
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Create price

export const createPrice = createAsyncThunk(
  'stripe/createPrice',
  async (
    { CustomerData, Product, amount, interval },
    { getState, rejectWithValue }
  ) => {
    try {
      const response = await fetch(
        `https://api.stripe.com/v1/prices?product=${Product.id}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${STRIPE_SECRET_KEY}`
          }
        }
      );
      const existingPrices = await response.json();

      if (existingPrices.data.length > 0) {
        const existingPrice = existingPrices.data[0];

        // Deactivate existing price (set it to inactive)
        await fetch(`https://api.stripe.com/v1/prices/${existingPrice.id}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${STRIPE_SECRET_KEY}`
          },
          body: new URLSearchParams({
            active: 'false'
          })
        });
      }

      // Create a new price
      const priceResponse = await fetch('https://api.stripe.com/v1/prices', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${STRIPE_SECRET_KEY}`
        },
        body: new URLSearchParams({
          currency: 'usd',
          unit_amount: amount * 100, // Convert to cents
          'recurring[interval]': interval, // "day", "week", "month", "year"
          product: Product.id,
          'metadata[payment_type]': 'subscription_payment'
        })
      });

      const newPrice = await priceResponse.json();
      if (newPrice.error) throw new Error(newPrice.error.message);

      return newPrice;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const attachPaymentMethod = createAsyncThunk(
  'payment/attachPaymentMethod',
  async ({ customerId, paymentMethodId }, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `https://api.stripe.com/v1/payment_methods/${paymentMethodId}/attach`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${STRIPE_SECRET_KEY}` // Ensure you use env variable
          },
          body: new URLSearchParams({ customer: customerId })
        }
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(
          data.error?.message || 'Failed to attach payment method'
        );
      }

      return data; // Return successful response
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const createSubscription = createAsyncThunk(
  'stripe/createSubscription',
  async ({ agency, customerId, priceId }, { rejectWithValue }) => {
    try {
      // Fetch existing subscriptions for the customer
      const existingSubscriptionsResponse = await fetch(
        `https://api.stripe.com/v1/subscriptions?customer=${customerId}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${STRIPE_SECRET_KEY}`
          }
        }
      );

      const existingSubscriptions = await existingSubscriptionsResponse.json();

      if (existingSubscriptions.data.length > 0) {
        const existingSubscription = existingSubscriptions.data[0];

        // If subscription already uses the same price, return it
        if (
          existingSubscription.items.data.some(
            (item) => item.price.id === priceId
          )
        ) {
          return existingSubscription;
        }

        // Update the existing subscription with the new price
        const updateResponse = await fetch(
          `https://api.stripe.com/v1/subscriptions/${existingSubscription.id}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              Authorization: `Bearer ${STRIPE_SECRET_KEY}`
            },
            body: new URLSearchParams({
              'items[0][id]': existingSubscription.items.data[0].id,
              'items[0][price]': priceId,
              'metadata[payment_type]': 'subscription_payment'
            })
          }
        );

        const updatedSubscription = await updateResponse.json();
        if (updatedSubscription.error)
          throw new Error(updatedSubscription.error.message);

        return updatedSubscription;
      }

      // If no subscription exists, create a new one

      const bodyParams = new URLSearchParams({
        customer: customerId,
        'items[0][price]': priceId,
        'metadata[payment_type]': 'subscription_payment'
      });

      Object.entries(agency).forEach(([key, value]) => {
        if (value) bodyParams.append(`metadata[${key}]`, value);
      });

      const response = await fetch('https://api.stripe.com/v1/subscriptions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${STRIPE_SECRET_KEY}`
        },
        body: bodyParams
      });

      const newSubscription = await response.json();
      if (newSubscription.error) throw new Error(newSubscription.error.message);

      return newSubscription;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Add funds to wallet
export const addFundsToWallet = createAsyncThunk(
  'stripe/addFundsToWallet',
  async (
    { agency, customerId, amount, type, token, paymentMethod },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        'https://api.stripe.com/v1/payment_intents',
        new URLSearchParams({
          amount: (amount * 100).toString(), // Convert to cents
          currency: 'usd', // You can set the currency as per your requirement
          customer: customerId,
          description: 'Add funds to wallet',
          payment_method: paymentMethod.id,
          confirm: 'true',
          'metadata[payment_type]': type,
          'metadata[payment_method]': token.type,
          'automatic_payment_methods[enabled]': 'true',
          'automatic_payment_methods[allow_redirects]': 'never',
          'metadata[user_id]': `${
            agency?.role === 'admin' ? agency?.id : agency.id
          }`
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${STRIPE_SECRET_KEY}`
          }
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const BuyLeadsDirectPayment = createAsyncThunk(
  'stripe/BuyLeadsDirectPayment',
  async (
    { agency, customerId, amount, type, leads, token, paymentMethod },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        'https://api.stripe.com/v1/payment_intents',
        new URLSearchParams({
          amount: (amount * 100).toString(), // Convert to cents
          currency: 'usd', // You can set the currency as per your requirement
          customer: customerId,
          description: 'Buy leads',
          payment_method: paymentMethod.id,
          confirm: 'true',
          'metadata[payment_type]': type,
          'metadata[leads]': leads.leads,
          'metadata[payment_method]': token.type,
          'automatic_payment_methods[enabled]': 'true',
          'automatic_payment_methods[allow_redirects]': 'never',
          'metadata[user_id]': `${
            agency?.role === 'admin' ? agency?.id : agency.id
          }`
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${STRIPE_SECRET_KEY}`
          }
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateCustomerBalance = createAsyncThunk(
  'stripe/updateCustomerBalance',
  async ({ customerId, amount }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `https://api.stripe.com/v1/customers/${customerId}/balance_transactions`,
        new URLSearchParams({
          amount: (amount * 100).toString(), // Convert dollars to cents
          currency: 'usd',
          description: 'Wallet Top-up'
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${STRIPE_SECRET_KEY}`
          }
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.error?.message || error.message
      );
    }
  }
);

export const buyLead = createAsyncThunk(
  'stripe/buyLead',
  async ({ agency, customerId, amount, type }, { rejectWithValue }) => {
    try {
      await axios.post(
        `https://api.stripe.com/v1/customers/${customerId}/balance_transactions`,
        new URLSearchParams({
          amount: -amount * 100, // Negative amount deducts balance
          currency: 'usd',
          description: 'Partial balance deduction',
          'metadata[payment_type]': type,
          'metadata[amount]': amount,
          'metadata[user_id]': `${agency?.id}`,
          'metadata[payment_method]': 'balance'
        }),
        {
          headers: {
            Authorization: `Bearer ${STRIPE_SECRET_KEY}`,
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      );

      const paymentIntentResponse = await axios.post(
        'https://api.stripe.com/v1/payment_intents',
        new URLSearchParams({
          customer: customerId,
          amount: amount * 100, // Charge remaining amount
          currency: 'usd',
          description: 'Payment for deducted balance',
          confirm: 'true', // Auto-confirm the payment
          'automatic_payment_methods[enabled]': 'true', // Enable automatic payment methods
          'automatic_payment_methods[allow_redirects]': 'never', // Prevent redirect-based methods
          'metadata[payment_type]': type,
          'metadata[amount]': amount,
          'metadata[user_id]': `${agency?.id}`,
          'metadata[payment_method]': 'balance'
        }),
        {
          headers: {
            Authorization: `Bearer ${STRIPE_SECRET_KEY}`,
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      );

      return paymentIntentResponse.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.error?.message ||
          error.message ||
          'Error processing payment'
      );
    }
  }
);

export const getWalletBalance = createAsyncThunk(
  'stripe/fetchWalletBalance',
  async (customerId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `https://api.stripe.com/v1/customers/${customerId}`,
        {
          headers: {
            Authorization: `Bearer ${STRIPE_SECRET_KEY}`
          }
        }
      );

      return response.data.balance / 100; // Convert cents to dollars
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.error?.message || error.message
      );
    }
  }
);

// Fetch wallet balance
export const getPaymentIntents = createAsyncThunk(
  'stripe/getPaymentIntents',
  async (customerId, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `https://api.stripe.com/v1/payment_intents?customer=${customerId}&limit=100`,
        {
          method: 'GET',
          headers: { Authorization: `Bearer ${STRIPE_SECRET_KEY}` }
        }
      );

      const data = await response.json();
      if (data.error) throw new Error(data.error.message);

      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getInvoices = createAsyncThunk(
  'stripe/getInvoices',
  async (customerId, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `https://api.stripe.com/v1/invoices?customer=${customerId}&limit=100`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${STRIPE_SECRET_KEY}`
          }
        }
      );

      const data = await response.json();
      if (data.error) throw new Error(data.error.message);

      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getSubcription = createAsyncThunk(
  'stripe/getSubcription',
  async (customerId, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `https://api.stripe.com/v1/subscriptions?customer=${customerId}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${STRIPE_SECRET_KEY}`
          }
        }
      );

      const data = await response.json();
      if (data.error) throw new Error(data.error.message);

      return data?.data[0] || null;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
// Redux slice
const stripeSlice = createSlice({
  name: 'stripe',
  initialState: {
    customer: null,
    products: [],
    prices: [],
    subscriptions: [],
    balance: 0,
    wallet: [],
    recurringPayment: null,
    paymentIntent: null, // Store payment intent data
    paymentIntents: [],
    paymentInvoices: [],
    subcription: null,
    loading: false,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.customer = action.payload;
        state.balance =
          action.payload === null ? 0 : action.payload.balance / 100;
        state.subcription = action.payload === null ? [] : state.subcription;
      })
      .addCase(fetchCustomer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.customer = action.payload;
      })
      .addCase(createCustomer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(createProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.products.push(action.payload);
      })
      .addCase(createProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createPrice.pending, (state) => {
        state.loading = true;
      })
      .addCase(createPrice.fulfilled, (state, action) => {
        state.loading = false;
        state.prices.push(action.payload);
      })
      .addCase(createPrice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createSubscription.pending, (state) => {
        state.loading = true;
      })
      .addCase(createSubscription.fulfilled, (state, action) => {
        state.loading = false;
        state.subcription = action.payload;
      })
      .addCase(createSubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getWalletBalance.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWalletBalance.fulfilled, (state, action) => {
        state.loading = false;
        state.wallet = action.payload.data;
        state.balance = action.payload;
        // state.balance = action.payload.data.reduce(
        //   (sum, payment) => sum + payment.amount / 100,
        //   0
        // );
      })
      .addCase(getWalletBalance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getSubcription.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSubcription.fulfilled, (state, action) => {
        state.loading = false;
        state.subcription = action.payload;
      })
      .addCase(getSubcription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addFundsToWallet.pending, (state) => {
        state.loading = true;
      })
      .addCase(addFundsToWallet.fulfilled, (state, action) => {
        state.loading = false;
        state.paymentIntent = action.payload; // Store payment intent data
      })
      .addCase(addFundsToWallet.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getPaymentIntents.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPaymentIntents.fulfilled, (state, action) => {
        state.loading = false;
        state.paymentIntents = action.payload.data || []; // Store payment intent data
      })
      .addCase(getPaymentIntents.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getInvoices.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInvoices.fulfilled, (state, action) => {
        state.loading = false;
        state.paymentInvoices = action.payload.data || []; // Store payment intent data
      })
      .addCase(getInvoices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default stripeSlice.reducer;
