import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { requestData } from '../../request';
import { message } from 'antd';
import { getSession } from '../../../lib/Auth';

// Create an agent
export const createAgent = createAsyncThunk(
  'agent/create',
  async ({ AgencyId, agentData }, { rejectWithValue }) => {
    try {
      const token = getSession();
      const data = await requestData(
        {
          url: `api/v1/agencies/${AgencyId}/agents`,
          method: 'POST',
          data: agentData,
          token: token?.token
        },
        { rejectWithValue }
      );
      if (typeof data?.payload === 'string') {
        message.error(data?.payload);
        return rejectWithValue(data?.payload);
      } else {
        message.success('Agent invite sent successfully');
        return data;
      }
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

// List all agents
export const listAgents = createAsyncThunk(
  'agents/list',
  async ({ page, pageSize }, { rejectWithValue }) => {
    try {
      const token = getSession();
      const queryParams = new URLSearchParams({
        page: page.toString(), // Convert to string for URL compatibility
        pageSize: pageSize.toString() // Convert to string for URL compatibility
      }).toString();
      const data = await requestData(
        {
          url: `api/v1/agents?${queryParams}`,
          method: 'GET',
          token: token?.token
        },
        { rejectWithValue }
      );
      if (typeof data?.payload === 'string') {
        message.error(data?.payload);
        return rejectWithValue(data?.payload);
      } else {
        return data;
      }
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

// List all agents search
export const listAgentsSearch = createAsyncThunk(
  'agents/list/search',
  async (name, { rejectWithValue }) => {
    try {
      const token = getSession();
      const data = await requestData(
        {
          url: `api/v1/agents?search=${name}`,
          method: 'GET',
          token: token?.token
        },
        { rejectWithValue }
      );
      if (typeof data?.payload === 'string') {
        message.error(data?.payload);
        return rejectWithValue(data?.payload);
      } else {
        return data;
      }
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

// List all agents of agency
export const listAgentsofAgency = createAsyncThunk(
  'agents/list/agency',
  async ({ id, page, pageSize }, { rejectWithValue }) => {
    try {
      const token = getSession();
      const queryParams = new URLSearchParams({
        page: page.toString(), // Convert to string for URL compatibility
        pageSize: pageSize.toString() // Convert to string for URL compatibility
      }).toString();
      const data = await requestData(
        {
          url: `api/v1/agencies/${id}/agents?${queryParams}`,
          method: 'GET',
          token: token?.token
        },
        { rejectWithValue }
      );
      if (typeof data?.payload === 'string') {
        message.error(data?.payload);
        return rejectWithValue(data?.payload);
      } else {
        return data;
      }
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

// List all agents of agency search
export const listAgentsofAgencySearch = createAsyncThunk(
  'agents/list/agency/search',
  async ({ name, id }, { rejectWithValue }) => {
    try {
      const token = getSession();
      const data = await requestData(
        {
          url: `api/v1/agencies/${id}/agents?search=${name}`,
          method: 'GET',
          token: token?.token
        },
        { rejectWithValue }
      );
      if (typeof data?.payload === 'string') {
        message.error(data?.payload);
        return rejectWithValue(data?.payload);
      } else {
        return data;
      }
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

// Update an agent
export const updateAgent = createAsyncThunk(
  'agents/update',
  async ({ id, agentData }, { rejectWithValue }) => {
    try {
      const token = getSession();
      const data = await requestData(
        {
          url: `api/v1/agents/${id}`,
          method: 'PUT',
          data: agentData,
          token: token?.token
        },
        { rejectWithValue }
      );
      if (typeof data?.payload === 'string') {
        message.error(data?.payload);
        return rejectWithValue(data?.payload);
      } else {
        message.success('Agent updated successfully');
        return data;
      }
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

// Update an agent
export const updateAgent2step = createAsyncThunk(
  'agents/updatestep2',
  async ({ id, agentData }, { rejectWithValue }) => {
    try {
      const token = getSession();
      const data = await requestData(
        {
          url: `api/v1/agents/${id}`,
          method: 'PUT',
          data: agentData,
          token: token?.token
        },
        { rejectWithValue }
      );
      message.success('Agent updated successfully');
      return data;
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

// Agent Pending Invites
export const agentPendingInvites = createAsyncThunk(
  'agents/list',
  async ({}, { rejectWithValue }) => {
    try {
      const token = getSession();
      const data = await requestData(
        {
          url: `api/v1/agents/pending_invite`,
          method: 'GET',
          token: token?.token
        },
        { rejectWithValue }
      );
      if (typeof data?.payload === 'string') {
        message.error(data?.payload);
        return rejectWithValue(data?.payload);
      } else {
        return data;
      }
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

// Delete an agent
export const deleteAgent = createAsyncThunk(
  'agents/delete',
  async (id, { rejectWithValue }) => {
    try {
      const token = getSession();
      await requestData(
        {
          url: `api/v1/agents/${id}`,
          method: 'DELETE',
          token: token?.token
        },
        { rejectWithValue }
      );
      message.success('Contract deleted successfully');
      return id;
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

export const AgentFetch = createAsyncThunk(
  'agents/agent',
  async (id, { rejectWithValue }) => {
    try {
      const token = getSession();
      const data = await requestData(
        {
          url: `api/v1/agents/${id}`,
          method: 'GET',
          token: token?.token
        },
        { rejectWithValue }
      );

      return data;
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

// agent slice
const agentsSlice = createSlice({
  name: 'agents',
  initialState: {
    agents: [],
    meta: null,
    agent: null,
    leadcount: null,
    loading: false,
    error: null
  },
  reducers: {
    AgentCreateUser: (state, action) => {
      state.agent = action.payload.agent;
    }
  },
  extraReducers: (builder) => {
    builder
      // Create agent
      .addCase(createAgent.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createAgent.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.agent = action.payload.agent;
        state.agents = [action.payload.agent, ...state.agents];
      })

      .addCase(createAgent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to create agent';
      })

      // fetch AgentFetch
      .addCase(AgentFetch.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AgentFetch.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.agent = action?.payload?.agent;
        state.leadcount = action?.payload?.leads_count;
      })

      .addCase(AgentFetch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to get AgentFetch';
      })

      // List agents
      .addCase(listAgents.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listAgents.fulfilled, (state, action) => {
        state.loading = false;
        state.agents = action.payload?.agents;
        state.meta = action.payload?.meta;
      })
      .addCase(listAgents.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch agent';
      })

      // List agents search
      .addCase(listAgentsSearch.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listAgentsSearch.fulfilled, (state, action) => {
        state.loading = false;
        state.agents = action.payload?.agents;
        state.meta = action.payload?.meta;
      })
      .addCase(listAgentsSearch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch agent';
      })

      // List of agents of Agency
      .addCase(listAgentsofAgency.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listAgentsofAgency.fulfilled, (state, action) => {
        state.loading = false;
        state.agents = action.payload?.agents;
        state.meta = action.payload?.meta;
      })
      .addCase(listAgentsofAgency.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch agent';
      })

      // List of agents of Agency search
      .addCase(listAgentsofAgencySearch.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listAgentsofAgencySearch.fulfilled, (state, action) => {
        state.loading = false;
        state.agents = action.payload?.agents;
        state.meta = action.payload?.meta;
      })
      .addCase(listAgentsofAgencySearch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch agent';
      })

      // Update agent
      .addCase(updateAgent.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAgent.fulfilled, (state, action) => {
        state.loading = false;
        state.agents = state.agents.map((agent) =>
          agent.id === action.payload.agent?.id
            ? { ...agent, ...action.payload.agent }
            : agent
        );
        state.agent = action.payload.agent;
      })
      .addCase(updateAgent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to update agent';
      })

      // Update agent 2nd step
      .addCase(updateAgent2step.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAgent2step.fulfilled, (state, action) => {
        state.loading = false;
        state.agents = state.agents.map((agent) =>
          agent.id === action.payload.agent?.id
            ? { ...agent, ...action.payload.agent }
            : agent
        );
        state.agent = null;
      })
      .addCase(updateAgent2step.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to update agent';
      })

      // Delete agent
      .addCase(deleteAgent.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteAgent.fulfilled, (state, action) => {
        state.loading = false;
        state.agents = state.agents.filter(
          (agent) => agent.id !== action.payload
        );
      })
      .addCase(deleteAgent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to delete agent';
      });
  }
});

export const { AgentCreateUser } = agentsSlice.actions;
export default agentsSlice.reducer;
