import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { requestDataWithoutToken, requestData } from '../../request'; // import the requestData function
import { message } from 'antd';
import { getSession } from '../../../lib/Auth';

// Create an agency
export const createAgency = createAsyncThunk(
  'agencies/create',
  async (agencyData, { rejectWithValue }) => {
    try {
      const token = getSession();
      const data = await requestData(
        {
          url: 'api/v1/agencies',
          method: 'POST',
          data: agencyData,
          token: token?.token
        },
        { rejectWithValue }
      );
      message.success('Agency created successfully');
      return data;
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

// List all agencies
export const listAgencies = createAsyncThunk(
  'agencies/list',
  async ({ page, pageSize }, { rejectWithValue }) => {
    try {
      const token = getSession();
      const queryParams = new URLSearchParams({
        page: page.toString(), // Convert to string for URL compatibility
        pageSize: pageSize.toString() // Convert to string for URL compatibility
      }).toString();
      const data = await requestData(
        {
          url: `api/v1/agencies?${queryParams}`,
          method: 'GET',
          token: token?.token
        },
        { rejectWithValue }
      );
      return data;
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

// Update an agency
export const updateAgency = createAsyncThunk(
  'agencies/update',
  async ({ id, agencyData }, { rejectWithValue }) => {
    try {
      const token = getSession();
      const data = await requestData(
        {
          url: `api/v1/agencies/${id}`,
          method: 'PUT',
          data: agencyData,
          token: token?.token
        },
        { rejectWithValue }
      );
      message.success('Agency updated successfully');
      return data;
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

// Update an agency
export const updateAgency2step = createAsyncThunk(
  'agencies/updatestep2',
  async ({ id, agencyData }, { rejectWithValue }) => {
    try {
      const token = getSession();
      const data = await requestData(
        {
          url: `api/v1/agencies/${id}`,
          method: 'PUT',
          data: agencyData,
          token: token?.token
        },
        { rejectWithValue }
      );
      message.success('Agency updated successfully');
      return data;
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

// Delete an agency
export const deleteAgency = createAsyncThunk(
  'agencies/delete',
  async (id, { rejectWithValue }) => {
    try {
      const token = getSession();
      await requestData(
        {
          url: `api/v1/agencies/${id}`,
          method: 'DELETE',
          token: token?.token
        },
        { rejectWithValue }
      );
      message.success('Agency deleted successfully');
      return id;
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

// leads cap
export const createAgencyLeadCap = createAsyncThunk(
  'agencies/create/leadcap',
  async ({ leadCapData, agency_id }, { rejectWithValue }) => {
    try {
      const token = getSession();
      const data = await requestData(
        {
          url: `api/v1/agencies/${agency_id}/lead_caps`,
          method: 'POST',
          data: leadCapData,
          token: token?.token
        },
        { rejectWithValue }
      );
      if (typeof data?.payload === 'string') {
        message.error(data?.payload);
        return rejectWithValue(data?.payload);
      } else {
        message.success('Agency lead cap created successfully');
        return data;
      }
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

export const updateAgencyLeadCap = createAsyncThunk(
  'agencies/update/leadcap',
  async ({ leadCapData, agency_id, id }, { rejectWithValue }) => {
    try {
      const token = getSession();
      const data = await requestData(
        {
          url: `api/v1/agencies/${agency_id}/lead_caps/${id}`,
          method: 'PUT',
          data: leadCapData,
          token: token?.token
        },
        { rejectWithValue }
      );
      if (typeof data?.payload === 'string') {
        message.error(data?.payload);
        return rejectWithValue(data?.payload);
      } else {
        message.success('Agency lead Cap updated successfully');
        return data;
      }
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);
// /api/v1/agencies/:id

export const AgenceyFetch = createAsyncThunk(
  'agencies/agency',
  async (id, { rejectWithValue }) => {
    try {
      const token = getSession();
      const data = await requestData(
        {
          url: `api/v1/agencies/${id}`,
          method: 'GET',
          token: token?.token
        },
        { rejectWithValue }
      );
      return data;
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

// Agencies slice
const agenciesSlice = createSlice({
  name: 'agencies',
  initialState: {
    agencies: [],
    meta: null,
    createdUser: null,
    viewagency: null,
    leadcap: null,
    loading: false,
    error: null
  },
  reducers: {
    AgenceCreateUser: (state, action) => {
      state.createdUser = action.payload.createdUser;
    },
    AgenceCreateUserView: (state, action) => {
      state.viewagency = action.payload.viewagency;
    }
  },
  extraReducers: (builder) => {
    builder
      // fetch agency
      .addCase(AgenceyFetch.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createdUser = null;
        state.viewagency = null;
        state.agencies = [];
      })
      .addCase(AgenceyFetch.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.viewagency = action.payload;
      })

      .addCase(AgenceyFetch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to get agency';
      })
      // Create agency
      .addCase(createAgency.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createAgency.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.createdUser = action.payload.agency;
        state.agencies = [action.payload.agency, ...state.agencies];
      })

      .addCase(createAgency.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to create agency';
      })

      // Create agency leadCap
      .addCase(createAgencyLeadCap.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createAgencyLeadCap.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.leadcap = action.payload.leadcap;
      })

      .addCase(createAgencyLeadCap.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to create agency leadCap';
      })

      // Update agency leadCap
      .addCase(updateAgencyLeadCap.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAgencyLeadCap.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.leadcap = action.payload.leadcap;
      })
      .addCase(updateAgencyLeadCap.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to update agency leadCap';
      })

      // List agencies
      .addCase(listAgencies.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listAgencies.fulfilled, (state, action) => {
        state.loading = false;
        state.agencies = action.payload?.agencies;
        state.meta = action.payload?.meta;
      })
      .addCase(listAgencies.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch agencies';
      })

      // Update agency
      .addCase(updateAgency.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAgency.fulfilled, (state, action) => {
        state.loading = false;
        state.agencies = state.agencies.map((agency) =>
          agency.id === action.payload.agency?.id
            ? { ...agency, ...action.payload.agency }
            : agency
        );
        state.createdUser = action.payload.agency;
        state.viewagency = action.payload.agency;
      })
      .addCase(updateAgency.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to update agency';
      })

      // Update agency 2nd step
      .addCase(updateAgency2step.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAgency2step.fulfilled, (state, action) => {
        state.loading = false;
        state.agencies = state.agencies.map((agency) =>
          agency.id === action.payload.agency?.id
            ? { ...agency, ...action.payload.agency }
            : agency
        );
        state.createdUser = action.payload.agency;
        state.viewagency = action.payload.agency;
      })
      .addCase(updateAgency2step.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to update agency';
      })

      // Delete agency
      .addCase(deleteAgency.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteAgency.fulfilled, (state, action) => {
        state.loading = false;
        state.agencies = state.agencies.filter(
          (agency) => agency.id !== action.payload
        );
      })
      .addCase(deleteAgency.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to delete agency';
      });
  }
});

export const { AgenceCreateUser, AgenceCreateUserView } = agenciesSlice.actions;
export default agenciesSlice.reducer;
