// import axios from 'axios';

// export const requestData = async (options, { rejectWithValue }) => {
//   try {
//     const fnOptions = {};

//     fnOptions['method'] = options?.method;
//     fnOptions['url'] = `${process.env.REACT_APP_BASE_URL}/${options?.url}`;

//     if (
//       fnOptions?.method === 'post' ||
//       fnOptions?.method === 'put' ||
//       fnOptions?.method === 'patch'
//     ) {
//       fnOptions['data'] = options?.data;
//     }
//     const response = await axios(options);

//     return await response.json();
//   } catch (error) {
//     const errorMessage =
//       error.response?.message || error?.message || 'An error occurred';
//     return rejectWithValue(errorMessage);
//   }
// };

// export const initialState = {
//   data: {},
//   loading: false,
//   error: null
// };

// export const reduxBuilder = (builder, thunkActionName) => {
//   builder
//     .addCase(thunkActionName.pending, state => {
//       state.loading = true;
//       state.error = null;
//     })
//     .addCase(thunkActionName.fulfilled, (state, action) => {
//       state.loading = false;
//       state.data = action.payload;
//       state.error = null;
//     })
//     .addCase(thunkActionName.rejected, (state, action) => {
//       state.loading = false;
//       state.error = action.payload;
//     });
// };

import axios from 'axios';

export const requestData = async (options, { rejectWithValue }) => {
  try {
    const fnOptions = {
      method: options?.method || 'GET',
      url: `${process.env.REACT_APP_API_URL}/${options?.url}`,
      data: options?.data || null,
      headers: {
        'Content-Type': 'application/json',
        ...(options?.token ? { Authorization: `${options.token}` } : {})
      }
    };

    const response = await axios(fnOptions);

    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.message ||
      (Array.isArray(error.response?.data?.errors) &&
      error.response.data.errors.length > 0
        ? error.response.data.errors[0]
        : 'An error occurred') ||
      error.response?.statusText ||
      error.message ||
      'An error occurred';
    return rejectWithValue(errorMessage);
  }
};

export const requestDataWithoutToken = async (options, { rejectWithValue }) => {
  try {
    // Build request options, no token required
    const fnOptions = {
      method: options?.method || 'GET',
      url: `${process.env.REACT_APP_API_URL}/${options?.url}`,
      data: options?.data || null,
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const response = await axios(fnOptions);

    // Return parsed response data
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.message ||
      error.response?.data?.errors[0] ||
      error.message ||
      'An error occurred';
    return rejectWithValue(errorMessage);
  }
};
